import { ISelectData } from '../ui/select/model'

export const BASE_URL = process.env.REACT_APP_BASE_URL
export const APP_NAME = process.env.REACT_APP_NAME
export const ACCESS_TOKEN_KEY = process.env.REACT_APP_COOKIES_ACCESS_TOKEN_KEY
export const REFRESH_TOKEN_KEY = process.env.REACT_APP_COOKIES_REFRESH_TOKEN_KEY
export const STORAGE_USERINFO_KEY = process.env.REACT_APP_STORAGE_USERINFO_KEY

export const USER_ROLE = {
  Admin: '1',
  Student: '2',
  Teacher: '3',
  Parent: '4'
}
export const GENDER: ISelectData[] = [
  {
    label: 'M',
    value: 1
  },
  {
    label: 'F',
    value: 2
  }
]

export const status: ISelectData[] = [
  {
    label: 'active',
    value: 1
  },
  {
    label: 'draft',
    value: 2
  }
]

export const EXCEL_FORMAT = ".xlsx,.xls,.csv'"

export const EditorConfigurationInResourceText = {
  toolbar: [
    'undo',
    'redo',
    '|',
    'heading',
    '|',
    'fontfamily',
    'fontsize',
    'fontColor',
    'fontBackgroundColor',
    '|',
    'bold',
    'italic',
    '|',
    'bulletedList',
    'numberedList',
    'todoList',
    'outdent',
    'indent'
  ],
  placeholder: 'Text',
  contentCss: ['.form-control']
}

export enum ACTIVATE_TYPE {
  Class = 'class',
  School = 'school',
  User = 'user'
}

export const GRADE: ISelectData[] = [
  {
    label: '1',
    value: 1
  },
  {
    label: '2',
    value: 2
  },
  {
    label: '3',
    value: 3
  },
  {
    label: '4',
    value: 4
  },
  {
    label: '5',
    value: 5
  },
  {
    label: '6',
    value: 6
  },
  {
    label: '7',
    value: 7
  },
  {
    label: '8',
    value: 8
  },
  {
    label: '9',
    value: 9
  },
  {
    label: '10',
    value: 10
  },
  {
    label: '11',
    value: 11
  },
  {
    label: '12',
    value: 12
  },
  {
    label: '13',
    value: 13
  }
]

export const DeepDiveMetricsType = {
  WellBeing: 'Well-Being',
  ChallengingConduct: 'Challenging Conduct',
  PeerSupport: 'Peer Support',
  SchoolConnectedness: 'School Connectedness',
  CASEL: 'CASEL',
  Stress: 'Stress',
  Resilience: 'Resilience',
  Bullying: 'Bullying',
  Mindfulness: 'Mindfulness',
  BodyImage: 'Body Image',
  EmotionalRegulation: 'Emotional Regulation',
  SelfBelief: 'Self Belief',
  Sleep: 'Sleep',
  Safeguarding: 'Safeguarding',
  AcademicEngagement: 'Academic Engagement',
  ScreenTime: 'Screen Time',
  Exercise: 'Exercise'
}
