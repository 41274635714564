import {
  Button,
  ButtonGroup,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Table,
  Tooltip
} from 'react-bootstrap'
import {
  useDeleteSurveyMutation,
  useGetSurveyQuery,
  useUpdateStatusMutation
} from '../../store/api/survey/survey.api'
import { stringToDate } from '../../utils/date'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Pagination from '../../ui/Pagination/Pagination'
import { useEffect, useState } from 'react'
import { ISearchParams } from '../../ui/filter/model'
import { searchParamsData } from '../../utils/searchParamsData'
import Loading from '../Loading/Loading'
import RemoveItemModal from '../../utils/RemoveItemModal/removeItemModal'
import { UseToggleModalHOC } from '../../hooks/toggleModal'
import { toast } from 'react-toastify'
import { IStatusUpdateData } from '../../store/api/survey/model'
import { store } from '../../store'

const Surveys = (): JSX.Element => {
  const { isModalVisible, toggleModal } = UseToggleModalHOC()
  const [deleteSurvey] = useDeleteSurveyMutation()
  const [putChangeStatus] = useUpdateStatusMutation()
  const navigate = useNavigate()
  const [deletedId, setDeletedId] = useState<number>(0)
  const [searchParams, setSearchParams] = useSearchParams({
    createdBy: String(store.getState().admin.admin.id)
  })
  const requestData: ISearchParams = {}
  const {
    isLoading,
    data: surveys,
    refetch
  } = useGetSurveyQuery(searchParamsData(searchParams, requestData))

  useEffect(() => {
    refetch()
  }, [searchParams])

  useEffect(() => {
    if (!searchParams.get('page') || !searchParams.get('pageSize')) {
      searchParams.set('page', '1')
      searchParams.set('pageSize', '20')
    }
    setSearchParams(searchParams)
  }, [])

  const changeStatus = async (data: IStatusUpdateData): Promise<void> => {
    try {
      await putChangeStatus(data)
      await refetch()
      toast.success('Status updated successfully')
    } catch (error) {
      console.log(error)
    }
  }

  // const showStatus = (givenTimeString: string, disable: boolean): string => {
  //   const nowDate = new Date()
  //   if (!disable) {
  //     return 'unpublished'
  //   }
  //   return nowDate > new Date(givenTimeString) ? 'closed' : 'ongoing'
  // }

  return (
    <div className='mt-5'>
      {isLoading && <Loading />}
      <Row className='row-sm'>
        <Col lg={12}>
          <Card className='custom-card'>
            <Card.Header>
              <Col xs={6} md={10}>
                <h3 className='card-title mb-1'>Survey</h3>
              </Col>
              <Col>
                <Button onClick={() => navigate('create')}>Create Survey</Button>
              </Col>
            </Card.Header>
            <Card.Body>
              <div className='table-responsive'>
                <Table className='table table-bordered text-nowrap text-md-nowrap mg-b-0'>
                  <thead>
                    <tr className='text-center'>
                      <th>title</th>
                      {/* <th>status</th> */}
                      <th>created At</th>
                      <th>enabled/disabled</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {surveys?.data?.length
                      ? surveys?.data.map((survey, index) => (
                          <tr key={index} className='text-center align-middle'>
                            <td>{survey.title}</td>
                            {/* <td>{showStatus(survey.created_at, Boolean(survey.enabled))}</td> */}
                            <td>{stringToDate(survey.created_at)}</td>
                            <td>
                              <div className='form-check form-switch'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  id='flexSwitchCheckDefault'
                                  defaultChecked={Boolean(survey.enabled)}
                                  onChange={e =>
                                    changeStatus({
                                      id: survey.id,
                                      enabled: Number(e.target.checked)
                                    })
                                  }
                                />
                              </div>
                            </td>
                            <td className='w-15'>
                              <ButtonGroup size='sm' className='flex-nowrap p-1'>
                                <OverlayTrigger placement='top' overlay={<Tooltip>Edit</Tooltip>}>
                                  <Button onClick={() => navigate(`edit/${survey.id}`)}>
                                    Edit
                                  </Button>
                                </OverlayTrigger>
                                <OverlayTrigger placement='top' overlay={<Tooltip>Delete</Tooltip>}>
                                  <Button
                                    onClick={() => {
                                      setDeletedId(survey.id)
                                      toggleModal()
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </OverlayTrigger>
                              </ButtonGroup>
                            </td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
          <Pagination
            setQuery={setSearchParams}
            numPages={surveys?.count / 20}
            query={searchParams}
          />
        </Col>
      </Row>
      {isModalVisible && (
        <RemoveItemModal
          id={deletedId}
          deleteItem={deleteSurvey}
          reload={refetch}
          onClose={() => toggleModal()}
        />
      )}
    </div>
  )
}
export default Surveys
