import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Button, Card, Col, Row, Table } from 'react-bootstrap'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  useGetUsersQuery,
  useSendExcelFileStudentMutation,
  useSendExcelFileTeacherMutation
} from '../../store/api/users/users.api'
import Pagination from '../../ui/Pagination/Pagination'
import UsersFilters from '../../ui/filter/UsersFilters'
import { searchParamsData } from '../../utils/searchParamsData'
import Loading from '../Loading/Loading'
import UsersTable from './UsersTable/UsersTable'
import { CustomMessageSuccsesOrError } from '../../utils/CustomMessageSuccsesOrError'
import { BASE_URL, EXCEL_FORMAT, USER_ROLE } from '../../utils/constants'
import { TParams } from '../../ui/filter/model'

const Users = (): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>()
  const navigate = useNavigate()
  const [sendExcelStudent] = useSendExcelFileStudentMutation()
  const [sendExcelTeacher] = useSendExcelFileTeacherMutation()
  const [searchData, setSearchData] = useState<TParams>({} as TParams)
  const [searchParams, setSearchParams] = useSearchParams()
  const roleId = searchParams.get('roleId')
  const {
    isLoading,
    data: users,
    refetch
  } = useGetUsersQuery({ ...searchData, ...searchParamsData(searchParams, {}) })

  useEffect(() => {
    refetch()
  }, [searchParams])

  const handleExcelFileChangeStudentOrTeacher = async (
    event: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const selectedFile = event.target.files?.[0]
    const formData = new FormData()

    if (selectedFile) {
      formData.append('file', selectedFile)
      const res =
        roleId === USER_ROLE.Student
          ? await sendExcelStudent(formData)
          : await sendExcelTeacher(formData)
      CustomMessageSuccsesOrError(res)
      refetch()
    }
  }

  const tableName = (roleId: string): string => {
    if (roleId === USER_ROLE.Admin) {
      return 'LeaderShips'
    } else if (roleId === USER_ROLE.Student) {
      return 'Students'
    } else if (roleId === USER_ROLE.Teacher) {
      return 'Teachers'
    } else if (roleId === USER_ROLE.Parent) {
      return 'Parents'
    } else if (roleId === '') {
      return 'Users'
    }
  }

  return (
    <div>
      {isLoading && <Loading />}
      <UsersFilters setQuery={setSearchData} query={searchData} role={roleId} />
      <Row className=' row-sm'>
        <Col lg={12}>
          <Card className='custom-card'>
            <Card.Header>
              <Col xs={6} md={10}>
                <h3 className='card-title mb-1'>{tableName(roleId)}</h3>
              </Col>
              <Col className='d-flex'>
                {roleId === USER_ROLE.Student ? (
                  <>
                    <div>
                      <a
                        download
                        href={`${BASE_URL}admin/export-user-data`}
                        className='btn btn-success'
                        target='_blank'
                      >
                        Export
                      </a>
                    </div>
                    <div className='mx-3'>
                      <input
                        ref={inputRef}
                        onChange={e => handleExcelFileChangeStudentOrTeacher(e)}
                        className='d-none'
                        type='file'
                        accept={EXCEL_FORMAT}
                      />
                      <button
                        type='button'
                        onClick={() => inputRef?.current.click()}
                        className='btn btn-success'
                      >
                        <span>
                          <i className='fe fe-log-in'></i>&nbsp;
                        </span>
                        Import Student
                      </button>
                    </div>
                  </>
                ) : null}
                {roleId === USER_ROLE.Teacher ? (
                  <div className='mx-3'>
                    <input
                      ref={inputRef}
                      onChange={e => handleExcelFileChangeStudentOrTeacher(e)}
                      className='d-none'
                      type='file'
                      accept={EXCEL_FORMAT}
                    />
                    <button
                      type='button'
                      onClick={() => inputRef?.current.click()}
                      className='btn btn-success'
                    >
                      <span>
                        <i className='fe fe-log-in'></i>&nbsp;
                      </span>
                      Import Teacher
                    </button>
                  </div>
                ) : null}
                <Button onClick={() => navigate('/users/create')}>Create User</Button>
              </Col>
            </Card.Header>
            <Card.Body>
              <div className='table-responsive'>
                <Table className='table table-bordered text-nowrap text-md-nowrap mg-b-0'>
                  <thead>
                    <tr className='text-center'>
                      <th>ID</th>
                      <th>Full Name</th>
                      <th>Email</th>
                      <th>status</th>
                      <th>package</th>
                      <th>verify</th>
                      <th>create at</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users?.data.length > 0 &&
                      users?.data.map(user => {
                        return <UsersTable key={user.id} user={user} refetch={refetch} />
                      })}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
            <Pagination
              setQuery={setSearchParams}
              numPages={Math.ceil(users?.count / Number(searchParams.get('pageSize')))}
              query={searchParams}
            />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Users
