import { TMenuitems } from './model'

export const MENUITEMS: TMenuitems[] = [
  {
    Items: [
      {
        path: '/dashboard',
        icon: 'home',
        type: 'link',
        active: true,
        title: 'Dashboard'
      },
      {
        path: '/lesson?page=1&pageSize=20',
        icon: 'leanpub',
        type: 'link',
        active: false,
        title: 'Lesson',
        children: [
          {
            path: '/lessons-plans',
            icon: 'check-square-o',
            type: 'link',
            active: false,
            title: 'Lessons-Plans'
          }
        ]
      },

      {
        path: '/users?page=1&pageSize=20',
        icon: 'users',
        type: 'link',
        active: false,
        title: 'Users',
        children: [
          {
            path: '/users/teachers?page=1&pageSize=20&roleId=3',
            icon: 'gift',
            type: 'link',
            active: false,
            title: 'Teachers'
          },
          {
            path: '/users/students?page=1&pageSize=20&roleId=2',
            icon: 'gift',
            type: 'link',
            active: false,
            title: 'Students'
          },
          {
            path: '/users/parents?page=1&pageSize=20&roleId=4',
            icon: 'gift',
            type: 'link',
            active: false,
            title: 'Parents'
          },
          {
            path: '/users/School/leaderships?page=1&pageSize=20&roleId=1',
            icon: 'gift',
            type: 'link',
            active: false,
            title: 'Leaderships'
          }
        ]
      },
      {
        path: '/wellness-checkins',
        icon: 'check',
        type: 'link',
        active: false,
        title: 'Wellness-Checkins'
      },
      {
        path: '/class?page=1&pageSize=20',
        icon: 'graduation-cap',
        type: 'link',
        active: false,
        title: 'Class'
      },
      {
        path: '/section?page=1&pageSize=20',
        icon: 'check',
        type: 'link',
        active: false,
        title: 'Section'
      },
      {
        path: '/groups',
        icon: 'users',
        type: 'link',
        active: false,
        title: 'Groups'
      },
      {
        path: '/characters',
        icon: 'area-chart',
        type: 'link',
        active: false,
        title: 'Characters'
      },
      {
        path: '/quizzes',
        icon: 'check-square-o',
        type: 'link',
        active: false,
        title: 'Quizzes'
      },
      {
        path: '/questions',
        icon: 'question',
        type: 'link',
        active: false,
        title: 'Questions'
      },
      {
        path: '/moods',
        icon: 'hand-rock-o',
        type: 'link',
        active: false,
        title: 'Moods'
      },
      {
        path: '/resources?page=1&pageSize=20',
        icon: 'industry',
        type: 'link',
        active: false,
        title: 'Resources',
        children: [
          {
            path: '/resources',
            icon: 'gift',
            type: 'link',
            active: false,
            title: 'Resources List'
          },
          {
            path: '/resources/category',
            icon: 'gift',
            type: 'link',
            active: false,
            title: 'Category'
          }
        ]
      },
      {
        path: '/schools',
        icon: 'building-o',
        type: 'link',
        active: false,
        title: 'Schools'
      },
      {
        path: '/welcome',
        icon: 'question-circle',
        type: 'link',
        active: false,
        title: 'Welcome'
      },
      {
        path: '/meditation',
        icon: 'check-square-o',
        type: 'link',
        active: false,
        title: 'Meditation'
      },
      {
        path: '/surveys',
        icon: 'check',
        type: 'link',
        active: false,
        title: 'Surveys'
      }
    ]
  }
]
