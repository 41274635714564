import React, { useEffect, useState } from 'react'
import { Card, Col, FormGroup, Row } from 'react-bootstrap'
import { Params, useNavigate, useParams } from 'react-router-dom'
import { ICreateQuiz, IGetQuiz } from '../../../store/api/quiz/model'
import {
  useCreateQuizMutation,
  useGetQuizByIdQuery,
  useUpdateQuizMutation
} from '../../../store/api/quiz/quiz.api'
import { closeValidateRequiredError } from '../../../validation/validation'
import { IValidationRequired } from '../../../validation/model'
import { responseError, validationRequaired } from '../../../validation/validationRequaired'
import { ISelectData } from '../../../ui/select/model'
import { useGetQuestionsQuery } from '../../../store/api/question/question.api'
import MultiSelect from '../../../ui/select/MultiSelect'
import { toast } from 'react-toastify'
import { extractValues } from '../../../utils/extractValues'
import { createSingleSelectData } from '../../../utils/createSingleSelectData'

const QuizCreate = (): JSX.Element => {
  const { id }: Readonly<Params<string>> = useParams()
  const navigate = useNavigate()
  const formRef = React.createRef<HTMLFormElement>()
  const [createQuiz] = useCreateQuizMutation()
  const [updateQuiz] = useUpdateQuizMutation()
  const { data: questions } = useGetQuestionsQuery()
  const [addQuestion, setAddQuestion] = useState<ISelectData[]>([])

  const [questionData, setQuestionData] = useState<ISelectData[]>([])
  const [errorRequaired, seterrorRequaired] = useState<IValidationRequired>({})
  const [quizById, setQuizById] = useState<IGetQuiz>()
  const { data, refetch } = useGetQuizByIdQuery(id, { skip: !id })

  useEffect(() => {
    setQuizById(data)
  }, [data])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()
    const newData: ICreateQuiz = {
      duration: formRef?.current.duration.value,
      title: formRef?.current.titlee.value,
      questionIds: extractValues(addQuestion)
    }

    seterrorRequaired(validationRequaired(newData))
    if (!Object.values(validationRequaired(newData)).length) {
      if (id) {
        newData.id = Number(id)
        const res = await updateQuiz(newData)
        if ('error' in res) {
          responseError(res)
        } else {
          toast.success('Quizz was updated')
          navigate(-1)
        }
      } else {
        const res = await createQuiz(newData)
        if ('error' in res) {
          responseError(res)
        } else {
          toast.success('Quizz was created')
          navigate(-1)
        }
      }
    }
  }

  useEffect(() => {
    if (quizById) {
      setAddQuestion([])
      quizById.questions.forEach(question => {
        setAddQuestion(prev => [
          ...prev,
          {
            value: question.id,
            label: String(question.id) + '. ' + question.text
          }
        ])
      })
    }
  }, [quizById])

  useEffect(() => {
    if (id) {
      refetch()
    }
  }, [id])

  useEffect(() => {
    if (questions?.length > 0) {
      questions.forEach(question => {
        setQuestionData(prev => {
          return [...prev, createSingleSelectData(question.text, question.id)]
        })
      })
    }
  }, [questions])
  return (
    <div>
      <Card.Header>
        <div>
          <h2>{id ? 'Edit Quiz' : 'Create Quiz'} </h2>
        </div>
      </Card.Header>
      <Row>
        <Col lg={12} xl={12} md={12} sm={12}>
          <Card>
            <form ref={formRef} onSubmit={e => handleSubmit(e)}>
              <Card.Body>
                <Row>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark' htmlFor='titleField'>
                        Title
                      </label>
                      <input
                        defaultValue={quizById?.title}
                        id='titleField'
                        name='titlee'
                        type='text'
                        className='form-control'
                        placeholder='Title'
                        onClick={() =>
                          closeValidateRequiredError('title', errorRequaired, seterrorRequaired)
                        }
                      />
                    </FormGroup>
                    {errorRequaired.title && (
                      <span className='text-danger'>{errorRequaired.title}</span>
                    )}
                  </Col>
                  <Col xs={6} md={12} className='mb-5'>
                    <FormGroup>
                      <label className='text-dark  mt-5' htmlFor='durationField'>
                        Duration
                      </label>
                      <input
                        defaultValue={quizById?.duration}
                        id='durationField'
                        name='duration'
                        type='text'
                        className='form-control'
                        placeholder='Duration'
                        onClick={() =>
                          closeValidateRequiredError('duration', errorRequaired, seterrorRequaired)
                        }
                      />
                    </FormGroup>
                    {errorRequaired.duration && (
                      <span className='text-danger'>{errorRequaired.duration}</span>
                    )}
                  </Col>
                  <Col xs={6} md={12}>
                    <label className='text-dark mt-5' htmlFor='exampleInputname'>
                      Question
                    </label>
                    <MultiSelect
                      setData={setAddQuestion}
                      data={questionData}
                      editData={addQuestion}
                    />
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className='text-end'>
                <button className='btn btn-success mt-1 me-2'>Save</button>
                <button
                  type='button'
                  className='btn btn-danger mt-1 me-2'
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </button>
              </Card.Footer>
            </form>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default QuizCreate
